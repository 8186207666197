import { Fragment, useRef, useContext } from "react";
import ComposeTweet from "./compose_tweet";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";

function ComposeModal({ isFocusMode, setIsFocusMode }) {
  return (
    <Modal
      isOpen={isFocusMode}
      onClose={() => {
        setIsFocusMode(false);
      }}
      scrollBehavior="inside"
      backdrop="blur"
      size="md"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              Start Writing
            </ModalHeader>
            <ModalBody>
              <ComposeTweet />
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}

export default ComposeModal;
