import React, { useState, Fragment, useContext, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react";

import { Icon } from "@iconify/react";

import {
  CreateTimeslot,
  getTimeslots,
  deleteTimeslotByPk,
  UpdateTimeslotByPk,
} from "../graphql";
import { useQuery, useMutation } from "urql";
import { AuthContext } from "../context/auth_context";

const QueueModal = ({ isQueue, setIsQueue }) => {
  const [scheduleSlots, setScheduleSlots] = useState([]);
  const [isAddingSlot, setIsAddingSlot] = useState(false);
  const [editingSlot, setEditingSlot] = useState(null);
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [selectedDays, setSelectedDays] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const [result, executeMutation] = useMutation(CreateTimeslot);
  const [resultDelete, executeDelete] = useMutation(deleteTimeslotByPk);
  const [resultUpdate, executeUpdate] = useMutation(UpdateTimeslotByPk);

  const { auth } = useContext(AuthContext);

  const [resultTimeslots] = useQuery({
    query: getTimeslots,
    variables: {
      user_id: auth?.data?.id,
    },
  });

  const { data: dataTimeslots } = resultTimeslots;

  useEffect(() => {
    if (dataTimeslots?.timeslot) {
      setScheduleSlots(dataTimeslots?.timeslot);
    }
  }, [dataTimeslots]);

  const weekDays = [
    { id: "mon", label: "Monday" },
    { id: "tue", label: "Tuesday" },
    { id: "wed", label: "Wednesday" },
    { id: "thu", label: "Thursday" },
    { id: "fri", label: "Friday" },
    { id: "sat", label: "Saturday" },
    { id: "sun", label: "Sunday" },
  ];

  const handleAddSlot = async () => {
    if (selectedDays.length === 0) {
      toast.error("Please select at least one day", {
        autoClose: 3000,
      });
      return;
    }

    // Extract hours and minutes from the selected time
    const hours = selectedTime.getHours();
    const minutes = selectedTime.getMinutes();

    // Create a time string in 24-hour format
    const timeString = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    // Check if time already exists in schedule slots
    const timeExists = scheduleSlots.some((slot) => slot.time === timeString);

    if (timeExists) {
      toast.error(
        "This time slot already exists. Please choose a different time.",
        {
          autoClose: 3000,
        }
      );
      return;
    }

    try {
      const result = await executeMutation({
        object: {
          user_id: auth.data.id,
          time: timeString,
          days: selectedDays,
        },
      });

      if (result.error) {
        throw new Error(result.error.message);
      }

      // Create new slot with the ID from the database
      const newSlot = {
        id: result.data.insert_timeslot_one.id,
        time: timeString,
        days: selectedDays,
      };

      setScheduleSlots((prevSlots) => [...prevSlots, newSlot]);
      setIsAddingSlot(false);
      setSelectedDays([]);
      setSelectedTime(new Date());
    } catch (error) {
      console.error("Error creating timeslot:", error);
      toast.error("Failed to create time slot", {
        autoClose: 3000,
      });
    }
  };

  const handleDeleteSlot = async (slotId) => {
    if (isDeleting) return;

    try {
      setIsDeleting(true);

      const result = await executeDelete({
        id: slotId,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }

      setScheduleSlots((prevSlots) =>
        prevSlots.filter((slot) => slot.id !== slotId)
      );
    } catch (error) {
      console.error("Error deleting timeslot:", error);
      toast.error("Failed to delete time slot", {
        autoClose: 3000,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const toggleDay = (dayId) => {
    if (selectedDays.includes(dayId)) {
      setSelectedDays(selectedDays.filter((d) => d !== dayId));
    } else {
      setSelectedDays([...selectedDays, dayId]);
    }
  };

  const toggleDayInSlot = (slotId, dayId) => {
    setScheduleSlots(
      scheduleSlots.map((slot) => {
        if (slot.id === slotId) {
          // If day exists, remove it; if it doesn't exist, add it
          const updatedDays = slot.days.includes(dayId)
            ? slot.days.filter((d) => d !== dayId)
            : [...slot.days, dayId];

          return {
            ...slot,
            days: updatedDays,
          };
        }
        return slot;
      })
    );
  };

  const handleEditSlot = (slot) => {
    // Convert time string to Date object for DatePicker
    const [hours, minutes] = slot.time.split(":");
    const date = new Date();
    date.setHours(parseInt(hours));
    date.setMinutes(parseInt(minutes));

    setSelectedTime(date);
    setSelectedDays(slot.days);
    setEditingSlot(slot);
    setIsAddingSlot(true);
  };

  const handleUpdateSlot = async () => {
    if (selectedDays.length === 0) {
      toast.error("Please select at least one day", {
        autoClose: 3000,
      });
      return;
    }

    const hours = selectedTime.getHours();
    const minutes = selectedTime.getMinutes();
    const timeString = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    // Check if time already exists in other slots
    const timeExists = scheduleSlots.some(
      (slot) => slot.time === timeString && slot.id !== editingSlot.id
    );

    if (timeExists) {
      toast.error(
        "This time slot already exists. Please choose a different time.",
        {
          autoClose: 3000,
        }
      );
      return;
    }

    try {
      const result = await executeUpdate({
        id: editingSlot.id,
        _set: {
          time: timeString,
          days: selectedDays,
        },
      });

      if (result.error) {
        throw new Error(result.error.message);
      }

      setScheduleSlots((prevSlots) =>
        prevSlots.map((slot) =>
          slot.id === editingSlot.id
            ? { ...slot, time: timeString, days: selectedDays }
            : slot
        )
      );

      setIsAddingSlot(false);
      setEditingSlot(null);
      setSelectedDays([]);
      setSelectedTime(new Date());

      toast.success("Time slot updated successfully!", {
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error updating timeslot:", error);
      toast.error("Failed to update time slot", {
        autoClose: 3000,
      });
    }
  };

  const renderScheduleView = () => {
    // Sort all slots by time
    const sortedSlots = [...scheduleSlots].sort((a, b) =>
      a.time.localeCompare(b.time)
    );

    return (
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-50 dark:bg-zinc-800">
              <th className="px-4 py-3 text-left text-sm font-semibold">
                Time
              </th>
              {weekDays.map((day) => (
                <th
                  key={day.id}
                  className="px-4 py-3 text-center text-sm font-semibold"
                >
                  {day.label.slice(0, 3)}
                </th>
              ))}
              <th className="px-4 py-3 text-right text-sm font-semibold">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-zinc-700">
            {sortedSlots.length > 0 ? (
              sortedSlots.map((slot) => {
                const formattedTime = new Date(
                  `2000-01-01T${slot.time}`
                ).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                });

                return (
                  <tr
                    key={slot.id}
                    className="hover:bg-gray-50 dark:hover:bg-zinc-800/50 transition-colors"
                  >
                    <td className="px-4 py-3">
                      <span className="font-medium text-blue-600 dark:text-blue-400">
                        {formattedTime}
                      </span>
                    </td>
                    {weekDays.map((day) => (
                      <td key={day.id} className="px-4 py-3 text-center">
                        {slot.days.includes(day.id) ? (
                          <Icon
                            icon="mdi:check-circle"
                            className="inline-block text-green-500"
                            width={20}
                          />
                        ) : (
                          <Icon
                            icon="mdi:minus-circle"
                            className="inline-block text-gray-300 dark:text-gray-600"
                            width={20}
                          />
                        )}
                      </td>
                    ))}
                    <td className="px-4 py-3">
                      <div className="flex justify-end gap-2">
                        <Button
                          size="sm"
                          color="primary"
                          variant="light"
                          isIconOnly
                          onClick={() => handleEditSlot(slot)}
                        >
                          <Icon icon="mdi:pencil" width={16} />
                        </Button>
                        <Button
                          size="sm"
                          color="danger"
                          variant="light"
                          isIconOnly
                          isDisabled={isDeleting}
                          onClick={() => handleDeleteSlot(slot.id)}
                        >
                          <Icon
                            icon={isDeleting ? "mdi:loading" : "mdi:trash"}
                            width={16}
                          />
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={9} className="px-4 py-8 text-center">
                  <div className="flex flex-col items-center gap-2">
                    <Icon
                      icon="mdi:calendar-blank"
                      className="text-gray-400"
                      width={48}
                    />
                    <p className="text-gray-500">No time slots scheduled yet</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const renderForm = () => (
    <div className="max-w-3xl mx-auto">
      <div className="bg-white dark:bg-zinc-800 rounded-xl shadow-lg">
        {/* Form Header */}
        <div className="p-6 border-b border-gray-200 dark:border-zinc-700">
          <div className="flex items-center gap-3">
            <Icon
              icon={editingSlot ? "mdi:pencil-clock" : "mdi:clock-plus"}
              className="text-blue-500"
              width={24}
            />
            <h3 className="text-lg font-medium">
              {editingSlot ? "Edit Time Slot" : "New Time Slot"}
            </h3>
          </div>
        </div>

        {/* Form Content */}
        <div className="p-6 space-y-6">
          {/* Time Selection */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Select Time
            </label>
            <div className="relative">
              <Icon
                icon="mdi:clock-outline"
                className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
                width={20}
              />
              <DatePicker
                selected={selectedTime}
                onChange={(date) => setSelectedTime(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-zinc-900 dark:border-zinc-700"
              />
            </div>
          </div>

          {/* Days Selection */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Select Days
            </label>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
              {weekDays.map((day) => (
                <button
                  key={day.id}
                  type="button"
                  onClick={() => toggleDay(day.id)}
                  className={`
                    flex items-center justify-center gap-2 p-3 rounded-lg border transition-all
                    ${
                      selectedDays.includes(day.id)
                        ? "bg-blue-50 border-blue-500 text-blue-700 dark:bg-blue-900/30 dark:text-blue-400"
                        : "bg-white border-gray-200 text-gray-700 hover:bg-gray-50 dark:bg-zinc-800 dark:border-zinc-700 dark:text-gray-300"
                    }
                  `}
                >
                  <Icon
                    icon={
                      selectedDays.includes(day.id)
                        ? "mdi:check-circle"
                        : "mdi:circle-outline"
                    }
                    width={18}
                  />
                  <span className="font-medium">{day.label}</span>
                </button>
              ))}
            </div>
            {selectedDays.length === 0 && (
              <p className="text-sm text-amber-600 dark:text-amber-400 flex items-center gap-1 mt-2">
                <Icon icon="mdi:alert-circle" width={16} />
                Please select at least one day
              </p>
            )}
          </div>
        </div>

        {/* Form Actions */}
        <div className="p-6 bg-gray-50 dark:bg-zinc-800/50 border-t border-gray-200 dark:border-zinc-700 rounded-b-xl flex items-center justify-end gap-3">
          <Button
            color="danger"
            variant="light"
            startContent={<Icon icon="mdi:close" width={20} />}
            onClick={() => {
              setIsAddingSlot(false);
              setEditingSlot(null);
              setSelectedDays([]);
              setSelectedTime(new Date());
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            startContent={
              <Icon icon={editingSlot ? "mdi:check" : "mdi:plus"} width={20} />
            }
            onClick={editingSlot ? handleUpdateSlot : handleAddSlot}
            isDisabled={selectedDays.length === 0}
          >
            {editingSlot ? "Update Slot" : "Add Slot"}
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Modal
        isOpen={isQueue}
        onOpenChange={(open) => setIsQueue(open)}
        size="5xl"
        scrollBehavior="inside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="border-b border-gray-200 dark:border-zinc-700">
                <div className="w-full">
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center gap-2">
                      <h2 className="text-xl font-semibold">Queue Schedule</h2>
                      <Icon
                        icon="mdi:clock-outline"
                        className="text-blue-500"
                        width={25}
                      />
                    </div>
                  </div>
                  {!isAddingSlot && (
                    <div className="flex items-center gap-4">
                      <div className="flex-1">
                        <p className="text-sm text-gray-500">
                          Manage your available time slots for the queue system
                        </p>
                      </div>
                      <Button
                        color="primary"
                        size="xs"
                        startContent={<Icon icon="mdi:plus" width={20} />}
                        onClick={() => {
                          setIsAddingSlot(true);
                          setSelectedDays(weekDays.map((day) => day.id));
                        }}
                        className="min-w-[160px]"
                      >
                        Add Time Slot
                      </Button>
                    </div>
                  )}
                </div>
              </ModalHeader>
              <ModalBody>
                {isAddingSlot ? renderForm() : renderScheduleView()}
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default QueueModal;
