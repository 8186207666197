import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { LoaderCircleIcon } from "../icons";

import { ComposeContext } from "../context/compose_tweet_context";
import {
  GetUserByPk,
  GetAIAssistents,
  GetDraftByAccountId,
  deleteCraftByPk,
  getTimeslots,
} from "../graphql";
import { useQuery, useMutation } from "urql";
import { AuthContext } from "../context/auth_context";
import { backend_url } from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TweetConfirmation from "./tweet_confirmation";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import EmojiPicker from "emoji-picker-react";

import PricingModal from "./pricing_modal";

import { toUnicodeVariant } from "./utils/unicode_varient";
import AdvancedOptions from "./advanced_options";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Spacer,
  Button,
  Accordion,
  AccordionItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownTrigger,
  Chip,
  Image,
  Tooltip,
  Tabs,
  Tab,
  Avatar,
  ScrollShadow,
} from "@nextui-org/react";
import { Icon } from "@iconify/react";

import Fireworks from "react-canvas-confetti/dist/presets/fireworks";

import ReactGiphySearchbox from "react-giphy-searchbox";
import MediaUploadModal from "./media_upload_modal";
import QueueModal from "./queue_modal";
function ComposeTweet({ close }) {
  const navigate = useNavigate();

  const [resultDelete, deleteCraft] = useMutation(deleteCraftByPk);

  const textareaRef = useRef(null);

  const {
    setTweet,
    tweet,
    setAiModal,
    aiTweet,
    setAiTweet,
    loading,
    setLoading,
    retry,
    setRetry,
    setConversation,
    cancel,
    setCancel,
    preview,
    setPreview,
    limitWarning,
    setLimitWarning,
    isThread,
    setIsThread,
    isRegenerate,
    setIsRegenerate,
    regClicked,
    setRegClicked,
    mediaPreview,
    setMediaPreview,
    threadMedia,
    setThreadMedia,
    selectedMedia,
    setSelectedMedia,
    setIsFocusMode,
    isComposeExpand,
    setIsComposeExpand,
  } = useContext(ComposeContext);

  useEffect(() => {
    // Focus on the textarea when the component mounts
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  const fileInputRef = useRef(null);

  const { auth, account } = useContext(AuthContext);
  dayjs.extend(utc);

  const [ai, setAi] = useState(false);
  const [assistents, setAssistents] = useState([]);
  const [tmpAssistId, setTmpAssistId] = useState("");
  const [isTweeting, setIsTweeting] = useState(false);
  const [isConfirmation, setConfirmation] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  );

  const [isScheduling, setIsScheduling] = useState(false);
  const [tweetHistory, setTweetHistory] = useState([]);
  const [isEmoji, setIsEmoji] = useState(false);
  const [tmpTweet, setTmpTweet] = useState("");
  const [extraStyles, setExtraStyles] = useState([]);
  const [isRichText, setIsRichText] = useState(false);
  const [isGifSelect, setIsGifSelect] = useState(false);
  // const [selectedMedia, setSelectedMedia] = useState([]);
  const [textStyling, setTextStyling] = useState("");
  const [isStyleDropdown, setIsStyleDropdown] = useState(false);
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [isPricing, setIsPricing] = useState(false);
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [isThreadDelay, setIsThreadDelay] = useState(false);
  const [selectedThreadDelay, setSelectedThreadDelay] = useState("10s");
  const [isAutoRetweet, setIsAutoRetweet] = useState(false);
  const [selectedRetweetInterval, setSelectedRetweetInterval] = useState(1);
  const [selectedRetweetTimes, setSelectedRetweetTimes] = useState("1");
  const [isAutoPlug, setIsAutoPlug] = useState(false);
  const [plugLikes, setPlugLikes] = useState(20);
  const [plugMessage, setPlugMessage] = useState("");
  const [isAutoDm, setIsAutoDm] = useState(false);
  const [dmMessage, setDmMessage] = useState("");
  const [dmLike, setDmLike] = useState(false);
  const [dmRetweet, setDmRetweet] = useState(false);
  const [dmReply, setDmReply] = useState(false);

  const [isAssistant, setIsAssistant] = useState(false);
  const [isFirework, setIsFirework] = useState(false);
  // const [mediaPreview, setMediaPreview] = useState([]);
  const [isGifAvailable, setIsGifAvailable] = useState(false);
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [isDrafting, setIsDrafting] = useState(false);
  const [drafts, setDrafts] = useState([]);
  const [selectedTab, setSelectedTab] = useState("compose-tab");
  const [isDraftDeleting, setIsDraftDeleting] = useState(false);
  const [tmpDraftId, setTmpDraftId] = useState(null);
  const [isQueue, setIsQueue] = useState(false);
  const [timeslots, setTimeslots] = useState([]);
  // useEffect(() => {
  //   setTmpTweet(tweet);
  // }, [tweet]);

  const [resultUser] = useQuery({
    query: GetUserByPk,
    variables: {
      id: auth?.data?.id,
    },
  });

  const { data: dataUser } = resultUser;

  useEffect(() => {
    if (dataUser?.user_by_pk) {
      console.log("dataUser?.user_by_pk", dataUser?.user_by_pk);
      const freeTrialEndsOn = dataUser?.user_by_pk?.free_trial_ends_on;
      const planId = dataUser?.user_by_pk?.plan_id;

      const freeTrialEndsDate = new Date(freeTrialEndsOn);
      const currentDate = new Date();

      if (planId) {
        setCurrentPlan(dataUser?.user_by_pk?.plan_id);
      } else if (freeTrialEndsDate > currentDate) {
        setCurrentPlan("trial");
      }
    }
  }, [dataUser]);

  const [resultTimeslots, reexecuteTimeslots] = useQuery({
    query: getTimeslots,
    variables: {
      user_id: auth?.data?.id,
    },
  });

  const { data: dataTimeslots } = resultTimeslots;

  const [result] = useQuery({
    query: GetAIAssistents,
  });

  const { data, fetching } = result;

  useEffect(() => {
    if (data?.ai_assistent?.length > 0) {
      setAssistents(data?.ai_assistent);
    }
  }, [data]);

  const [resultDrafts, refetchDrafts] = useQuery({
    query: GetDraftByAccountId,
    variables: {
      account_id: account?.data?.id,
    },
  });

  const { data: dataDrafts } = resultDrafts;

  useEffect(() => {
    console.log("data", dataDrafts);
    if (dataDrafts?.tweet_draft?.length > 0) {
      setDrafts(dataDrafts?.tweet_draft);
    }
  }, [dataDrafts]);

  const MAX_CHARACTERS = 280;

  useEffect(() => {
    if (retry > 0) {
      setLoading(true);
      handleAssistent(tmpAssistId, true);
      setRetry(0);
    }
  }, [retry]);

  useEffect(() => {
    if (regClicked === true) {
      setLoading(true);
      console.log("regenerate");
      handleAssistentRegenerate();
      setRegClicked(false);
      setIsRegenerate(false);
    }
  }, [regClicked]);

  useEffect(() => {
    if (cancel) {
      setTweetHistory([]);
    }
  }, [cancel]);

  const handleAssistent = async (id, retry) => {
    console.log("currentPlan", currentPlan);
    if (!currentPlan) {
      setIsPricing(true);
      return;
    }
    setIsAssistant(false);
    setCancel(false);
    if (!account?.data?.id) {
      toast.error("Select a account to proceed", {
        autoClose: 5000,
      });
      setTimeout(() => {
        navigate("/settings");
      }, 4000);
      return;
    }

    if (!tweet) {
      toast.error("Tweet is a required field", {
        autoClose: 5000,
      });
      return;
    }
    const toPostTweet = retry ? aiTweet : tweet;
    setAiModal(true);
    setTmpAssistId(id);
    try {
      setAiTweet("");

      const conversation = tweetHistory;

      conversation.push({ role: "user", content: toPostTweet });
      setConversation(conversation);

      const response = await fetch(`${backend_url}/openai/generate-tweet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          twitter_account_id: account?.data?.id,
          user_token: auth?.data?.jwt,
          assist_id: id,
          original_tweet: toPostTweet,
          conversation,
        }),
      });

      if (!response.ok) {
        console.log("res failed");
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result?.data);
      if (result?.message === "success") {
        const responseTweet =
          result?.data?.tweets?.tweet ||
          result?.data?.tweets?.[0]?.tweet ||
          result?.data?.tweets;
        conversation.push({ role: "assistant", content: responseTweet });
        setTweetHistory(conversation);
        setConversation(conversation);
        setAi(!ai);
      } else if (result?.message === "exceeded") {
        toast.error("Daily query limit exceeded. Please try again tomorrow.", {
          autoClose: 5000,
        });
      }

      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      // toast.error("Something went wrong, please try again", {
      //   autoClose: 5000,
      // });
      setLoading(false);
      // setAiModal(false);
      // setAiTweet("");

      const conversation = tweetHistory;

      conversation.push({ role: "assistant", content: "failed" });
      setConversation(conversation);
      setIsRegenerate(true);
    }
  };

  const handleAssistentRegenerate = async () => {
    try {
      const tmpConveration = tweetHistory;
      tmpConveration.pop();
      const conversation = tmpConveration;

      console.log("converstation", conversation);

      const response = await fetch(`${backend_url}/openai/generate-tweet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          twitter_account_id: account?.data?.id,
          user_token: auth?.data?.jwt,
          assist_id: tmpAssistId,
          original_tweet: "",
          conversation,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result?.data);

      if (result?.message === "success") {
        const responseTweet =
          result?.data?.tweets?.tweet ||
          result?.data?.tweets?.[0]?.tweet ||
          result?.data?.tweets;
        conversation.push({ role: "assistant", content: responseTweet });
        setTweetHistory(conversation);
        setConversation(conversation);
        setAi(!ai);
        setAi(!ai);
      } else if (result?.message === "exceeded") {
        toast.error("Daily query limit exceeded. Please try again tomorrow.", {
          autoClose: 5000,
        });
      }

      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      // toast.error("Something went wrong, please try again", {
      //   autoClose: 5000,
      // });
      setLoading(false);
      // setAiModal(false);
      // setAiTweet("");

      const conversation = tweetHistory;

      conversation.push({ role: "assistant", content: "failed" });
      setConversation(conversation);
      setIsRegenerate(true);
    }
  };

  // const handleTextareaChange = (content, delta, source, editor) => {
  //   setTweet(editor.getHTML());
  // };

  const handleTextareaChange = (e) => {
    const newText = e.target.value;
    // if (newText != "") {
    //   setPreview(true);
    // } else {
    //   setPreview(false);
    // }

    setTweet(newText);
    setTmpTweet(newText);
  };

  const handleTweetNow = async () => {
    if (!currentPlan) {
      setIsPricing(true);
      return;
    }

    if (!account?.data?.id) {
      toast.error("Select a account to proceed", {
        autoClose: 5000,
      });
      setTimeout(() => {
        navigate("/settings");
      }, 4000);
      return;
    }

    if (!tweet) {
      toast.error("Tweet is a required field", {
        autoClose: 5000,
      });
      return;
    }
    setIsScheduled(false);
    setConfirmation(true);
  };

  const uploadToS3 = async (file, presignedUrl) => {
    const response = await fetch(presignedUrl, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type,
      },
    });

    if (!response.ok) {
      throw new Error("Upload failed");
    }

    return presignedUrl.split("?")[0];
  };

  const getPresignedUrl = async (fileName, type) => {
    const response = await fetch(
      `${backend_url}/tweets/generate-presigned-url`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fileName: fileName,
          type: type,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    console.log(result);
    if (result?.message === "success") {
      return result?.data;
    }
  };

  const resetForm = () => {
    setTweet("");
    setPreview(false);
    setIsThread(false);
    setIsThreadDelay(false);
    setSelectedThreadDelay("10s");
    setSelectedMedia([]);
    setThreadMedia([]);
    setMediaPreview([]);
    setIsAutoPlug(false);
    setPlugLikes(20);
    setPlugMessage("");
    setIsAutoDm(false);
    setDmMessage("");
    setDmLike(false);
    setDmRetweet(false);
    setDmReply(false);
    setIsAdvanced(false);
  };

  const confirmTweetNow = async () => {
    console.log("main media", selectedMedia);
    console.log("thread media", threadMedia);

    console.log("is retweet", isAutoRetweet);
    console.log("interval", selectedRetweetInterval);
    console.log("times", selectedRetweetTimes);

    setConfirmation(false);

    setIsTweeting(true);

    const mediaFiles = await Promise.all(
      selectedMedia.map(async (media) => {
        console.log("media", media);
        if (typeof media === "string" && media.startsWith("http")) {
          console.log("Media is a URL");

          return media;
        } else if (media) {
          console.log("media is a file");

          // Generate pre-signed URL
          const fileName = `${Date.now()}_${media.name}`;
          console.log("file name", fileName);
          const presignedUrl = await getPresignedUrl(fileName, media?.type);
          console.log("presigned url", presignedUrl);
          // Upload the file to S3
          const s3Url = await uploadToS3(media, presignedUrl);
          console.log("s3 url", s3Url);
          return s3Url;
        }
      })
    );

    console.log("media files", mediaFiles);

    // Upload thread media
    const threadMediaFiles = await Promise.all(
      threadMedia.map(async (mediaArray, threadIndex) => {
        if (mediaArray && mediaArray.length > 0) {
          const uploadedThreadMedia = await Promise.all(
            mediaArray.map(async (media) => {
              if (typeof media === "string" && media.startsWith("http")) {
                return media;
              } else if (media) {
                const fileName = `${Date.now()}_${media.name}`;
                const presignedUrl = await getPresignedUrl(
                  fileName,
                  media?.type
                );
                const s3Url = await uploadToS3(media, presignedUrl);
                return s3Url;
              }
            })
          );
          return { threadIndex, media: uploadedThreadMedia };
        }
        return null;
      })
    );

    console.log("thread media files", threadMediaFiles);

    // Filter out empty thread media arrays
    const filteredThreadMediaFiles = threadMediaFiles.filter(Boolean);

    try {
      const response = await fetch(`${backend_url}/tweets/post-tweet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          twitter_account_id: account?.data?.id,
          user_token: auth?.data?.jwt,
          tweet: tweet.trim(),
          media: mediaFiles,
          threadMedia: filteredThreadMediaFiles || [],
          isThreadDelay: isThreadDelay,
          selectedThreadDelay: selectedThreadDelay,
          isAutoRetweet: isAutoRetweet,
          selectedRetweetInterval: Number(selectedRetweetInterval),
          selectedRetweetTimes: Number(selectedRetweetTimes),
          isAutoPlug: isAutoPlug,
          plugLikes: Number(plugLikes),
          plugMessage: plugMessage,
          isAutoDm: isAutoDm,
          dmMessage: dmMessage,
          dmLike: dmLike,
          dmRetweet: dmRetweet,
          dmReply: dmReply,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result);
      if (result?.message === "success") {
        toast.success("Tweet posted successfully!", {
          autoClose: 5000,
        });

        setIsFirework(true);

        setTimeout(() => {
          setIsFirework(false);
        }, 1500);

        if (isAutoDm) {
          //if auto dm is enabled create a alarm in extension
          const data = {
            type: "CREATE_ALARM_FOR_AUTO_DM",
          };
          window.postMessage(data, "*");

          const dmHandler = function (event) {
            // We only accept messages from ourselves
            if (event.source !== window) return;
          };

          // Add the event listener
          window.addEventListener("message", dmHandler);

          // Clean up the event listener when the component unmounts
          return () => {
            window.removeEventListener("message", dmHandler);
          };
        }

        resetForm();
      } else if (result?.message === "reconnect-account") {
        toast.error(
          "Something went wrong or Profile expired. Please reconnect your profile.",
          {
            autoClose: 5000,
          }
        );
        setTimeout(() => {
          navigate("/settings");
        }, 4000);
      } else {
        toast.error(result?.message, {
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        "Something went wrong or Profile expired. Please reconnect your profile.",
        {
          autoClose: 5000,
        }
      );
    } finally {
      setIsTweeting(false);
    }
  };

  const handleDraft = async () => {
    if (!tweet) {
      toast.error("Tweet is a required field", {
        autoClose: 5000,
      });
      return;
    }
    setIsDrafting(true);

    const mediaFiles = await Promise.all(
      selectedMedia.map(async (media) => {
        console.log("media", media);
        if (typeof media === "string" && media.startsWith("http")) {
          console.log("Media is a URL");

          return media;
        } else if (media) {
          console.log("media is a file");

          // Generate pre-signed URL
          const fileName = `${Date.now()}_${media.name}`;
          console.log("file name", fileName);
          const presignedUrl = await getPresignedUrl(fileName, media?.type);
          console.log("presigned url", presignedUrl);
          // Upload the file to S3
          const s3Url = await uploadToS3(media, presignedUrl);
          console.log("s3 url", s3Url);
          return s3Url;
        }
      })
    );

    console.log("media files", mediaFiles);

    // Upload thread media
    const threadMediaFiles = await Promise.all(
      threadMedia.map(async (mediaArray, threadIndex) => {
        if (mediaArray && mediaArray.length > 0) {
          const uploadedThreadMedia = await Promise.all(
            mediaArray.map(async (media) => {
              if (typeof media === "string" && media.startsWith("http")) {
                return media;
              } else if (media) {
                const fileName = `${Date.now()}_${media.name}`;
                const presignedUrl = await getPresignedUrl(
                  fileName,
                  media?.type
                );
                const s3Url = await uploadToS3(media, presignedUrl);
                return s3Url;
              }
            })
          );
          return uploadedThreadMedia.filter(Boolean); // Return the array of media URLs for this thread
        }
        return null; // No media for this thread, return null
      })
    );

    // Ensure the structure starts with null at index 0
    const structuredThreadMediaFiles = [
      null,
      ...threadMediaFiles.filter(Boolean),
    ];

    console.log("structuredThreadMediaFiles", structuredThreadMediaFiles);

    try {
      const response = await fetch(`${backend_url}/tweets/draft-tweet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          twitter_account_id: account?.data?.id,
          tweet: tweet,
          media: mediaFiles,
          threadMedia: threadMediaFiles || [],
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result);
      if (result?.message === "success") {
        toast.success("Tweet drafted successfully!", {
          autoClose: 3000,
        });
        resetForm();
        refetchDrafts();
      }
      setIsDrafting(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong", {
        autoClose: 5000,
      });
    }
  };

  const handleUseDraft = async (tweet) => {
    console.log("tweet", tweet);
    setTweet(tweet?.text);
    setSelectedMedia(tweet?.media);
    setThreadMedia(tweet?.thread_media);
    setSelectedTab("compose-tab");
  };

  const handleDeleteCraft = async (id) => {
    setIsDraftDeleting(true);
    setTmpDraftId(id);
    await deleteCraft({
      id: id,
    });
    refetchDrafts();
    setTmpDraftId(null);
    setIsDraftDeleting(false);
  };

  // const intercom_works = async () => {
  //   const response = await fetch(`${backend_url}/user/delete-intercom`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       user_token: auth?.data?.jwt,
  //     }),
  //   });

  //   if (!response.ok) {
  //     throw new Error(`HTTP error! Status: ${response.status}`);
  //   }
  // };

  // useEffect(() => {
  //   intercom_works();
  // }, []);

  const handleScheduleTweet = () => {
    if (!currentPlan) {
      setIsPricing(true);
      return;
    }

    if (!account?.data?.id) {
      toast.error("Select a account to proceed", {
        autoClose: 5000,
      });
      setTimeout(() => {
        navigate("/settings");
      }, 4000);
      return;
    }

    if (!tweet) {
      toast.error("Tweet is a required field", {
        autoClose: 5000,
      });
      return;
    }
    setIsScheduled(true);
    setConfirmation(true);
  };

  const confirmScheduleTweet = async (isQueue) => {
    console.log(
      "scheduled time",
      isQueue === true ? nextAvailableSlot : scheduledDate
    );
    const scheduledTime =
      isQueue === true
        ? new Date(nextAvailableSlot) // Convert nextAvailableSlot to match scheduledDate format
        : scheduledDate;
    const isFutureDateTime = scheduledTime > new Date();

    if (!isFutureDateTime) {
      toast.error("Please select a future date and time.", {
        autoClose: 5000,
      });
      return; // Add return to prevent continuing with invalid date
    }

    setConfirmation(false);
    setIsScheduled(false);
    setIsScheduling(true);

    const mediaFiles = await Promise.all(
      selectedMedia.map(async (media) => {
        console.log("media", media);
        if (typeof media === "string" && media.startsWith("http")) {
          console.log("Media is a URL");

          return media;
        } else if (media) {
          console.log("media is a file");
          // Generate pre-signed URL
          const fileName = `${Date.now()}_${media.name}`;
          console.log("file name", fileName);
          const presignedUrl = await getPresignedUrl(fileName, media?.type);
          console.log("presigned url", presignedUrl);
          // Upload the file to S3
          const s3Url = await uploadToS3(media, presignedUrl);
          console.log("s3 url", s3Url);

          // // Upload the file to S3
          // const s3Url = await uploadToS3(media);
          // console.log("s3 url", s3Url);

          return s3Url;
        }
      })
    );

    // Upload thread media
    const threadMediaFiles = await Promise.all(
      threadMedia.map(async (mediaArray, threadIndex) => {
        if (mediaArray && mediaArray.length > 0) {
          const uploadedThreadMedia = await Promise.all(
            mediaArray.map(async (media) => {
              if (typeof media === "string" && media.startsWith("http")) {
                return media;
              } else if (media) {
                const fileName = `${Date.now()}_${media.name}`;
                const presignedUrl = await getPresignedUrl(
                  fileName,
                  media?.type
                );
                const s3Url = await uploadToS3(media, presignedUrl);
                return s3Url;
              }
            })
          );
          return { threadIndex, media: uploadedThreadMedia };
        }
        return null;
      })
    );

    console.log("thread media files", threadMediaFiles);

    // Filter out empty thread media arrays
    const filteredThreadMediaFiles = threadMediaFiles.filter(Boolean);

    try {
      const response = await fetch(`${backend_url}/tweets/schedule-tweet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          scheduled_at: scheduledTime, // Use the scheduledTime variable
          twitter_account_id: account?.data?.id,
          user_token: auth?.data?.jwt,
          tweet: tweet.trim(),
          media: mediaFiles,
          threadMedia: filteredThreadMediaFiles || [],
          isThreadDelay: isThreadDelay,
          selectedThreadDelay: selectedThreadDelay,
          isAutoRetweet: isAutoRetweet,
          selectedRetweetInterval: Number(selectedRetweetInterval),
          selectedRetweetTimes: Number(selectedRetweetTimes),
          isAutoPlug: isAutoPlug,
          plugLikes: Number(plugLikes),
          plugMessage: plugMessage,
          isAutoDm: isAutoDm,
          dmMessage: dmMessage,
          dmLike: dmLike,
          dmRetweet: dmRetweet,
          dmReply: dmReply,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result);
      if (result?.message === "success") {
        toast.success(
          isQueue
            ? "Tweet added to queue successfully"
            : "Tweet scheduled successfully",
          { autoClose: 5000 }
        );
        resetForm();
      } else {
        throw new Error(result?.message || "Failed to schedule tweet");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message || "Something went wrong, please try again", {
        autoClose: 5000,
      });
    } finally {
      setIsScheduling(false);
    }
  };

  const handleEmojiClick = (emojiObject, event) => {
    const { emoji } = emojiObject;

    const cursorPos = textareaRef.current.selectionStart;

    // Split the tweet into two parts at the cursor position
    const firstPart = tweet.substring(0, cursorPos);
    const secondPart = tweet.substring(cursorPos);

    const updatedTweet = firstPart + emoji + secondPart;

    setTweet(updatedTweet);

    // Close the emoji picker
    setIsEmoji(false);
  };

  const handleStyleClick = (style, extra = []) => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    let selectedText = tweet.substring(start, end);
    let updatedStyles = textStyling;

    let converted = null;
    if (style) {
      if (style === updatedStyles) {
        updatedStyles = ""; // Remove the style if it exists
        converted = selectedText.normalize("NFKC");
      } else {
        selectedText = selectedText.normalize("NFKC");
        updatedStyles = style; // Add the style if it doesn't exist
      }
      setTextStyling(updatedStyles);
    }

    if (!converted) {
      converted = toUnicodeVariant(selectedText, updatedStyles, extra);
    }
    console.log("converted", converted);
    // Apply all active styles to the selected text

    setTweet(tweet.substring(0, start) + converted + tweet.substring(end));

    const newStart = start;
    const newEnd = newStart + converted.length;

    // Set a timeout to ensure that the textarea is updated before setting the selection range
    setTimeout(() => {
      // Set the selection range to keep the text selected
      textarea.setSelectionRange(newStart, newEnd);
      textarea.focus(); // Ensure textarea is focused after setting selection range
    }, 0);
    setIsStyleDropdown(false);
  };

  const handleUnderlineClick = () => {
    const tmpExtra = extraStyles;

    const styleIndex = tmpExtra.indexOf("underline");
    if (styleIndex !== -1) {
      tmpExtra.splice(styleIndex, 1);
    } else {
      tmpExtra.push("underline");
    }
    setExtraStyles(tmpExtra);

    handleStyleClick(null, tmpExtra);
  };

  const handleStrikeClick = () => {
    const tmpExtra = extraStyles;

    const styleIndex = tmpExtra.indexOf("strike");
    if (styleIndex !== -1) {
      tmpExtra.splice(styleIndex, 1);
    } else {
      tmpExtra.push("strike");
    }
    setExtraStyles(tmpExtra);
    handleStyleClick(null, tmpExtra);
  };

  const handleSelectGif = async (gifObject) => {
    const gifUrl = gifObject.images.original.url;
    console.log("gifUrl", gifUrl);
    setSelectedMedia((prev) => [...prev, gifUrl]);
    const gifPreview = {
      type: "image", // or 'gif' if you want to be specific
      url: gifUrl,
    };

    setMediaPreview((prev) => [...prev, gifPreview]);
    setIsGifAvailable(true);

    // setSelectedMedia([...selectedMedia, gifUrl]);

    // try {
    //   const response = await fetch(gifUrl);
    //   if (!response.ok) {
    //     throw new Error(`Failed to fetch GIF from URL: ${gifUrl}`);
    //   }
    //   const blob = await response.blob();
    //   const reader = new FileReader();
    //   reader.onload = () => {
    //     const base64Data = reader.result; // Base64 data of the GIF
    //     setSelectedMedia([...selectedMedia, base64Data]);
    //   };
    //   reader.readAsDataURL(blob);
    // } catch (error) {
    //   console.error(error);
    //   // Handle error
    // }
    setIsGifSelect(false);
    setIsUploadModal(false);
  };

  const handleFileUpload = (event) => {
    console.log("file changed");
    setIsUploadModal(false);
    const files = Array.from(event.target.files);
    console.log("selectedMedia", selectedMedia);
    let photoCount = selectedMedia.filter(
      (media) =>
        media?.type?.startsWith("image/") && media?.type !== "image/gif"
    ).length;

    let videoCount = selectedMedia.filter((media) =>
      media?.type?.startsWith("video/")
    ).length;

    let gifCount = selectedMedia.filter(
      (media) =>
        typeof media === "string" &&
        media.startsWith("http") &&
        media.endsWith(".gif")
    ).length;

    console.log("gif length", gifCount);

    for (let file of files) {
      if (file.type.startsWith("image/") && file.type !== "image/gif") {
        if (gifCount > 0 || videoCount > 0) {
          toast.error("You can't upload photos with a GIF or video.", {
            autoClose: 5000,
          });
          event.target.value = null;

          return;
        }
        if (photoCount >= 4) {
          toast.error("You can only upload up to 4 photos.", {
            autoClose: 5000,
          });
          event.target.value = null;

          return;
        }
        photoCount++;
      } else if (file.type === "image/gif") {
        if (photoCount > 0 || videoCount > 0) {
          toast.error("You can't upload a GIF with photos or video.", {
            autoClose: 5000,
          });
          event.target.value = null;

          return;
        }
        if (gifCount >= 1) {
          setIsGifAvailable(true);

          toast.error("You can only upload 1 GIF.", {
            autoClose: 5000,
          });
          event.target.value = null;

          return;
        }
        gifCount++;
      } else if (file.type.startsWith("video/")) {
        if (photoCount > 0 || gifCount > 0) {
          toast.error("You can't upload a video with photos or a GIF.", {
            autoClose: 5000,
          });
          event.target.value = null;

          return;
        }
        if (videoCount >= 1) {
          toast.error("You can only upload 1 video.", {
            autoClose: 5000,
          });
          event.target.value = null;

          return;
        }
        videoCount++;
      } else {
        toast.error("Unsupported media type.", {
          autoClose: 5000,
        });
        event.target.value = null;

        return;
      }
    }
    setSelectedMedia((prevSelectedMedia) => [...prevSelectedMedia, ...files]);

    const filePreviews = files.map((file) => ({
      type: file.type.startsWith("video") ? "video" : "image",
      url: URL.createObjectURL(file),
    }));

    setMediaPreview((prevMediaPreview) => [
      ...prevMediaPreview,
      ...filePreviews,
    ]);

    event.target.value = null;
  };

  const deleteMedia = (index) => {
    // Remove from selectedMedia
    const updatedMedia = selectedMedia.filter((_, i) => i !== index);
    setSelectedMedia(updatedMedia);

    // Remove from mediaPreview
    const updatedPreview = mediaPreview.filter((_, i) => i !== index);
    setMediaPreview(updatedPreview);
  };

  useEffect(() => {
    const threads = tweet.split(/\n\s*\n\s*\n/);
    for (let i = 0; i < threads.length; i++) {
      const tweet = threads[i];
      if (tweet.length > MAX_CHARACTERS && !account?.data?.is_blue_tick) {
        setLimitWarning(true);
        if (!isErrorShown) {
          toast.error(
            "Twitter API doesn't support 280+ Characters turn this into a thread",
            { autoClose: 5000 }
          );
          setIsErrorShown(true);
        }
        break;
      } else {
        setLimitWarning(false);
      }
    }
    if (threads.length > 1) {
      setIsThread(true);
      // setPreview(true);
    } else {
      setIsThread(false);
      // setPreview(false);
    }
    if (tweet) {
      setPreview(true);
    } else {
      setPreview(false);
    }
  }, [tweet]);

  // Determine if the GIF selector should be disabled
  const isGifDisabled = selectedMedia.some(
    (media) =>
      media?.type?.startsWith("image/") || media?.type?.startsWith("video/")
  );

  // Determine if the image/video selector should be disabled
  const isMediaDisabled = selectedMedia.some(
    (media) => typeof media === "string" && media.startsWith("http")
  );

  const MemoizedTweetConfirmation = React.memo(TweetConfirmation);
  const MemoizedPricingModal = React.memo(PricingModal);

  const [nextAvailableSlot, setNextAvailableSlot] = useState(null);

  useEffect(() => {
    if (dataTimeslots?.timeslot) {
      const nextSlot = getNextAvailableSlot(dataTimeslots.timeslot);
      setNextAvailableSlot(nextSlot);
    }
  }, [dataTimeslots]);

  const getNextAvailableSlot = (timeslots) => {
    if (!timeslots || timeslots.length === 0) return null;

    const now = new Date();
    const currentDay = now
      .toLocaleDateString("en-US", { weekday: "short" })
      .toLowerCase();
    const currentTime = now.getHours() * 60 + now.getMinutes(); // Convert current time to minutes

    // Sort timeslots by time
    const sortedTimeslots = [...timeslots].sort((a, b) => {
      const timeA =
        parseInt(a.time.split(":")[0]) * 60 + parseInt(a.time.split(":")[1]);
      const timeB =
        parseInt(b.time.split(":")[0]) * 60 + parseInt(b.time.split(":")[1]);
      return timeA - timeB;
    });

    // First, try to find a slot today
    for (const slot of sortedTimeslots) {
      if (slot.days.includes(currentDay)) {
        const [hours, minutes] = slot.time.split(":");
        const slotTime = parseInt(hours) * 60 + parseInt(minutes);

        if (slotTime > currentTime) {
          const nextSlot = new Date();
          nextSlot.setHours(parseInt(hours), parseInt(minutes), 0);
          return nextSlot;
        }
      }
    }

    // If no slot found today, find the next available day
    const weekdays = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    const currentDayIndex = weekdays.indexOf(currentDay);

    // Look through next 7 days
    for (let i = 1; i <= 7; i++) {
      const nextDayIndex = (currentDayIndex + i) % 7;
      const nextDay = weekdays[nextDayIndex];

      for (const slot of sortedTimeslots) {
        if (slot.days.includes(nextDay)) {
          const [hours, minutes] = slot.time.split(":");
          const nextSlot = new Date();
          nextSlot.setDate(nextSlot.getDate() + i);
          nextSlot.setHours(parseInt(hours), parseInt(minutes), 0);
          return nextSlot;
        }
      }
    }

    return null;
  };

  return (
    <>
      {isFirework && <Fireworks autorun={{ speed: 5 }} />}
      <MemoizedPricingModal isPricing={isPricing} setIsPricing={setIsPricing} />
      <MemoizedTweetConfirmation
        isConfirmation={isConfirmation}
        setConfirmation={setConfirmation}
        tweet={tweet}
        isScheduled={isScheduled}
        confirmTweetNow={confirmTweetNow}
        confirmScheduleTweet={confirmScheduleTweet}
        scheduledDate={scheduledDate}
        setScheduledDate={setScheduledDate}
      />
      <MediaUploadModal
        isUploadModal={isUploadModal}
        setIsUploadModal={setIsUploadModal}
        handleFileUpload={handleFileUpload}
        fileInputRef={fileInputRef}
        isGifDisabled={isGifDisabled}
        handleSelectGif={handleSelectGif}
      />
      <QueueModal
        isQueue={isQueue}
        setIsQueue={setIsQueue}
        onClose={() => {
          reexecuteTimeslots(); // Refresh timeslots when queue modal closes
        }}
      />
      <div className="h-full">
        <ToastContainer />
        <Card className={`h-screen rounded-none`} isBlurred shadow="sm">
          <Tabs
            key="draft"
            variant="underlined"
            aria-label="Tabs variants"
            selectedKey={selectedTab}
            onSelectionChange={setSelectedTab}
          >
            <Tab
              key="compose-tab"
              title={
                <div className="flex items-center space-x-2">
                  <Icon icon="fluent:compose-12-regular" width={20} />
                  <span>Compose</span>
                </div>
              }
            >
              <ScrollShadow className="h-[650px]">
                <CardHeader className="flex justify-between py-1">
                  {/* <div className="text-xs text-default-500">Start writing</div> */}
                  <Button
                    color="primary"
                    variant="light"
                    size="sm"
                    startContent={
                      <Icon icon="ic:baseline-center-focus-strong" width={20} />
                    }
                    onClick={() => setIsComposeExpand(!isComposeExpand)}
                  >
                    {isComposeExpand ? "Minimize" : "Expand"}
                  </Button>
                  {/* <Button
                    color="primary"
                    variant="light"
                    size="sm"
                    startContent={
                      <Icon icon="ic:baseline-center-focus-strong" width={20} />
                    }
                    onClick={() => setIsFocusMode(true)}
                  >
                    Focus Writing
                  </Button> */}
                  <Button
                    color="default"
                    variant="light"
                    size="sm"
                    startContent={<Icon icon="ic:baseline-plus" width={20} />}
                    onClick={handleDraft}
                    isLoading={isDrafting}
                  >
                    Add to Draft
                  </Button>
                </CardHeader>
                <Divider />
                <CardBody>
                  <div>
                    <textarea
                      ref={textareaRef}
                      className="border h-72 dark:bg-zinc-900 text-xs rounded-md border-gray-300 w-full p-3 text-default-600 focus:outline-none dark:border-gray-600 overflow-y-auto"
                      rows="17"
                      placeholder={`Start writing your Tweet here... ${String.fromCharCode(
                        10
                      )} ${String.fromCharCode(10)} ${String.fromCharCode(
                        10
                      )}Skip 3 lines to start a thread.`}
                      value={tweet}
                      onChange={handleTextareaChange}
                    ></textarea>

                    {!isThread &&
                      tweet.length > MAX_CHARACTERS &&
                      !account?.data?.is_blue_tick && (
                        <div
                          data-slot="helper-wrapper"
                          className="group-data-[has-helper=true]:flex p-1 relative flex-col gap-1.5"
                        >
                          <div
                            data-slot="error-message"
                            className="text-tiny text-danger"
                            id="react-aria8498452396-:r6h:"
                          >
                            The Twitter API doesn’t support long posts at the
                            moment. We are working with the Twitter team and
                            hope to add this feature soon.
                          </div>
                        </div>
                      )}

                    {!isThread && !account?.data?.is_blue_tick && (
                      <div
                        data-slot="helper-wrapper"
                        className="group-data-[has-helper=true]:flex p-1 relative flex-col gap-1.5"
                      >
                        <div
                          data-slot="description"
                          className="text-xs text-foreground-400"
                        >
                          {tweet.length} / {MAX_CHARACTERS}
                        </div>
                      </div>
                    )}

                    <div className="grid grid-cols-6 gap-2 my-2">
                      {selectedMedia.map((media, mediaIndex) => (
                        <div key={mediaIndex}>
                          {typeof media === "string" ? (
                            <Image
                              alt={`GIF ${mediaIndex}`}
                              src={media} // Use the GIF URL
                              radius="sm"
                            />
                          ) : media?.type?.startsWith("video") ? (
                            <video
                              controls
                              className="w-full h-auto rounded-sm"
                            >
                              <source src={URL.createObjectURL(media)} />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <Image
                              alt={`Thread Media ${mediaIndex}`}
                              src={URL.createObjectURL(media)}
                              radius="sm"
                            />
                          )}

                          <Icon
                            icon="carbon:close-outline"
                            size={16}
                            className="mx-auto cursor-pointer"
                            onClick={() => deleteMedia(mediaIndex)}
                          />
                        </div>
                      ))}
                    </div>

                    <Divider orientation="horizontal" />
                    <Spacer y={1} />

                    <div className="flex items-center justify-around">
                      <Tooltip
                        content={
                          isMediaDisabled
                            ? "You can choose either 1 GIF, 1 video or up to 4 images"
                            : "Upload image, video, or GIF"
                        }
                      >
                        <label className="cursor-pointer rounded-md p-1 text-gray-600 hover:text-gray-800 hover:bg-gray-200">
                          <Icon
                            icon="mdi:tray-upload"
                            width={17}
                            className={`${
                              isMediaDisabled
                                ? "text-default-400"
                                : "dark:text-default-500"
                            }`}
                            onClick={() => setIsUploadModal(true)}
                          />
                        </label>
                      </Tooltip>
                      {/* Toggle Rich Text */}
                      <div
                        className={`p-1 rounded-md text-gray-600 hover:text-gray-800 hover:bg-gray-200 cursor-pointer ${
                          isRichText ? "bg-gray-200" : ""
                        }`}
                        onClick={() => setIsRichText(!isRichText)}
                      >
                        <Icon
                          icon="mdi:format-text"
                          width={17}
                          className="dark:text-default-500"
                        />
                      </div>

                      {/* Toggle Emoji Picker */}
                      <div
                        className={`p-1 rounded-md hover:bg-blue-200 cursor-pointer ${
                          isEmoji ? "bg-blue-200" : ""
                        }`}
                        onClick={() => setIsEmoji(!isEmoji)}
                      >
                        <Icon
                          icon="mdi:emoji-outline"
                          width={17}
                          className="text-blue-700 dark:text-green-600"
                        />
                      </div>

                      <Popover
                        placement="right"
                        isOpen={isAssistant}
                        onOpenChange={(open) => setIsAssistant(open)}
                        shadow="lg"
                      >
                        <PopoverTrigger>
                          <Button
                            size="sm"
                            color="secondary"
                            variant="ghost"
                            endContent={<Icon icon="tabler:bulb" width={13} />}
                            // onClick={() => setIsAssistant(!isAssistant)}
                          >
                            AI Assistant
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <div className="grid grid-cols-2 gap-4 overflow-y-auto m-2 max-w-[350px]">
                            {fetching ? (
                              <LoaderCircleIcon />
                            ) : (
                              assistents?.map((assistent, idx) => (
                                <Button
                                  key={idx}
                                  variant="bordered"
                                  color="secondary"
                                  onClick={() =>
                                    handleAssistent(assistent?.id, false)
                                  }
                                  startContent={<Icon icon={assistent?.icon} />}
                                  size="sm"
                                >
                                  {assistent?.name}
                                </Button>
                              ))
                            )}
                          </div>
                        </PopoverContent>
                      </Popover>
                    </div>
                    {isRichText && (
                      <>
                        <Spacer y={2} />
                        <Divider />
                        <Spacer y={2} />
                        <div
                          className="flex items-center justify-between"
                          style={{ marginBottom: "-6px" }}
                        >
                          <div
                            className="text-default-500 font-medium cursor-pointer"
                            onClick={() => handleStyleClick("b")}
                          >
                            <Icon icon="mdi:format-bold" size={18} />
                          </div>
                          <div
                            className="text-default-500 font-medium cursor-pointer"
                            onClick={() => handleStyleClick("i")}
                          >
                            <Icon icon="mdi:format-italic" size={18} />
                          </div>
                          <div
                            className="text-default-500 font-medium cursor-pointer"
                            onClick={() => handleStyleClick("bi")}
                          >
                            <Icon
                              icon="mdi:format-italic"
                              size={18}
                              className="text-gray-800 dark:text-gray-200"
                            />
                          </div>

                          <div
                            className="text-default-500 font-medium cursor-pointer"
                            onClick={() => handleStyleClick("m")}
                          >
                            <Icon icon="mdi:code-tags" size={18} />
                          </div>
                          <Dropdown>
                            <DropdownTrigger>
                              <Button variant="light" isIconOnly>
                                <Icon icon="mdi:keyboard-arrow-down" />
                              </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                              aria-label="Static Actions"
                              onAction={(key) => handleStyleClick(key)}
                            >
                              <DropdownItem key="w">
                                Fullwidth Text
                              </DropdownItem>
                              <DropdownItem key="c">Script</DropdownItem>
                              <DropdownItem key="g">Gothic</DropdownItem>
                              <DropdownItem key="d">Double Struck</DropdownItem>
                              <DropdownItem key="o">Circled</DropdownItem>
                              <DropdownItem key="q">Squared</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </>
                    )}
                    <Spacer y={1} />

                    <Divider />

                    <Spacer y={2} />

                    <div className="flex justify-between items-center w-full gap-2">
                      <Dropdown>
                        <DropdownTrigger>
                          <Button
                            size="sm"
                            color="primary"
                            variant="bordered"
                            className="h-[50px] py-2"
                            isLoading={isScheduling}
                            isDisabled={isScheduling}
                          >
                            <div className="flex flex-col items-start gap-1">
                              <span className="font-medium text-sm">
                                Schedule Options
                              </span>
                              {nextAvailableSlot ? (
                                <div className="flex items-center gap-1 text-xs text-primary">
                                  {/* <Icon icon="mdi:clock-outline" width={14} /> */}
                                  <span>
                                    Next:{" "}
                                    {dayjs(nextAvailableSlot).format(
                                      "MMM D, h:mm A"
                                    )}
                                  </span>
                                </div>
                              ) : (
                                <span className="text-xs text-default-400">
                                  No slots available
                                </span>
                              )}
                            </div>
                            <Icon
                              icon="solar:clock-circle-linear"
                              className="text-primary"
                              width={16}
                            />
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                          aria-label="Schedule options"
                          className="w-[280px]"
                        >
                          <DropdownItem
                            key="queue"
                            description={
                              nextAvailableSlot
                                ? `Posts ${dayjs(nextAvailableSlot).format(
                                    "MMM D"
                                  )} at ${dayjs(nextAvailableSlot).format(
                                    "h:mm A"
                                  )}`
                                : "Configure queue times in settings"
                            }
                            startContent={
                              <Icon
                                icon="mdi:playlist-plus"
                                className={
                                  nextAvailableSlot
                                    ? "text-primary"
                                    : "text-default-400"
                                }
                              />
                            }
                            onClick={() =>
                              nextAvailableSlot
                                ? confirmScheduleTweet(true)
                                : null
                            }
                            isDisabled={!nextAvailableSlot}
                            isLoading={isScheduling}
                            className="py-2"
                          >
                            <div className="flex items-center gap-2">
                              <span>Add to Queue</span>
                              {nextAvailableSlot && (
                                <Chip size="sm" variant="flat" color="primary">
                                  Recommended
                                </Chip>
                              )}
                            </div>
                          </DropdownItem>
                          <DropdownItem
                            key="schedule"
                            startContent={
                              <Icon
                                icon="mdi:calendar-clock"
                                className="text-secondary"
                              />
                            }
                            description="Pick a custom date and time"
                            onClick={() => handleScheduleTweet()}
                            className="py-2"
                          >
                            Custom Schedule
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>

                      <Button
                        size="sm"
                        color="primary"
                        variant="solid"
                        isLoading={isTweeting}
                        onClick={() => handleTweetNow()}
                        endContent={
                          !isTweeting && <Icon icon="mdi:send-outline" />
                        }
                      >
                        Tweet Now
                      </Button>
                    </div>
                    <Spacer y={2} />
                    <Divider />
                    <Spacer y={1} />
                    <div className="flex justify-between">
                      <Button
                        size="sm"
                        variant="light"
                        color="primary"
                        endContent={
                          <Icon
                            icon={
                              isAdvanced
                                ? "mdi:keyboard-arrow-left"
                                : "mdi:keyboard-arrow-right"
                            }
                            width={18}
                          />
                        }
                        onClick={() => setIsAdvanced(!isAdvanced)}
                      >
                        Advanced Options
                      </Button>
                      <Button
                        size="sm"
                        color="primary"
                        variant="light"
                        isLoading={isTweeting}
                        onClick={() => setIsQueue(true)}
                        endContent={<Icon icon="ic:baseline-edit" />}
                      >
                        Edit Queue
                      </Button>
                    </div>
                    <div>
                      <div>
                        <EmojiPicker
                          open={isEmoji}
                          lazyLoadEmojis={true}
                          onEmojiClick={handleEmojiClick}
                          theme={
                            localStorage.getItem("theme")
                              ? localStorage.getItem("theme")
                              : "light"
                          }
                          emojiStyle="twitter"
                          width={300}
                        />
                      </div>

                      {isGifSelect && (
                        <div className="flex justify-center">
                          <ReactGiphySearchbox
                            apiKey="zpgHg1e3m6vBwiwgYAEBdUJnhWjuC7eA" // Required: get your on https://developers.giphy.com
                            onSelect={handleSelectGif}
                            columns={3}
                          />
                        </div>
                      )}

                      <Spacer y={2} />

                      {isAdvanced && (
                        <div className="px-1 py-2 mb-10">
                          <AdvancedOptions
                            setIsThreadDelay={setIsThreadDelay}
                            isThreadDelay={isThreadDelay}
                            selectedThreadDelay={selectedThreadDelay}
                            setSelectedThreadDelay={setSelectedThreadDelay}
                            isAutoRetweet={isAutoRetweet}
                            setIsAutoRetweet={setIsAutoRetweet}
                            selectedRetweetInterval={selectedRetweetInterval}
                            setSelectedRetweetInterval={
                              setSelectedRetweetInterval
                            }
                            selectedRetweetTimes={selectedRetweetTimes}
                            setSelectedRetweetTimes={setSelectedRetweetTimes}
                            isAutoPlug={isAutoPlug}
                            setIsAutoPlug={setIsAutoPlug}
                            plugLikes={plugLikes}
                            setPlugLikes={setPlugLikes}
                            plugMessage={plugMessage}
                            setPlugMessage={setPlugMessage}
                            isAutoDm={isAutoDm}
                            setIsAutoDm={setIsAutoDm}
                            dmMessage={dmMessage}
                            setDmMessage={setDmMessage}
                            dmLike={dmLike}
                            dmRetweet={dmRetweet}
                            dmReply={dmReply}
                            setDmLike={setDmLike}
                            setDmReply={setDmReply}
                            setDmRetweet={setDmRetweet}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </CardBody>
              </ScrollShadow>
            </Tab>
            <Tab
              key="draft-tab"
              title={
                <div className="flex items-center space-x-2">
                  <Icon icon="carbon:rule-draft" width={20} />
                  <span>Drafts</span>
                </div>
              }
            >
              <ScrollShadow className="h-[650px]">
                <div className="grid grid-cols-1 gap-4 mt-6 mr-1">
                  {drafts?.length > 0 &&
                    drafts.map((tweet, index) => (
                      <Card
                        key={index}
                        className="border border-default-200"
                        shadow="md"
                        isHoverable
                      >
                        <CardHeader className="justify-between items-start gap-2">
                          <div className="flex gap-3 max-w-[200px]">
                            <div className="w-8 h-8">
                              <Avatar
                                isBordered
                                radius="full"
                                size="md"
                                className="w-8 h-8"
                                src={account?.data?.profile_pic}
                              />
                            </div>

                            <div
                              className={`flex flex-col items-start justify-center ${
                                !account?.data?.verified && "gap-1"
                              }`}
                            >
                              <div className="flex gap-1 items-center">
                                {account?.data?.name && (
                                  <h4 className="text-xs font-semibold leading-none text-default-600">
                                    {account?.data?.name}
                                  </h4>
                                )}
                                {account?.data?.verified && (
                                  <Icon
                                    icon="mage:verified-check-fill"
                                    className="text-blue-500"
                                    width={20}
                                  />
                                )}
                              </div>
                              {account?.data?.username && (
                                <h5 className="text-xs tracking-tight text-default-400">
                                  @{account?.data?.username}
                                </h5>
                              )}
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody className="flex flex-col px-3 py-0 text-small text-default-500 max-h-[100px] overflow-hidden whitespace-pre-line">
                          {tweet.text}
                        </CardBody>
                        <CardFooter className="flex justify-end items-center gap-3">
                          <Button
                            size="sm"
                            isIconOnly
                            variant="light"
                            color="danger"
                            onClick={() => handleDeleteCraft(tweet?.id)}
                            isLoading={
                              isDraftDeleting && tweet.id === tmpDraftId
                            }
                          >
                            <Icon
                              icon="material-symbols:delete-outline"
                              width={25}
                            />
                          </Button>
                          <Button
                            onClick={() => {
                              handleUseDraft(tweet);
                            }}
                            size="sm"
                            variant="ghost"
                            color="primary"
                          >
                            Edit & Tweet
                          </Button>
                        </CardFooter>
                      </Card>
                    ))}
                </div>
              </ScrollShadow>
            </Tab>
          </Tabs>
        </Card>
      </div>
    </>
  );
}

export default ComposeTweet;
