import { lazy } from "react";

// use lazy for better code splitting, a.k.a. load faster
const Home = lazy(() => import("../pages/Home"));
const Inspirations = lazy(() => import("../pages/Inspirations"));
const SearchTweet = lazy(() => import("../pages/SearchTweet"));
const IndustrySearch = lazy(() => import("../pages/IndustrySearch"));

const MyCollection = lazy(() => import("../pages/MyCollection"));
const Settings = lazy(() => import("../pages/Settings"));
const Queue = lazy(() => import("../pages/Queue"));
const PostedTweet = lazy(() => import("../pages/PostedTweet"));
const Analytics = lazy(() => import("../pages/Analytics"));
const TwitterEngage = lazy(() => import("../pages/TwitterEngage"));
const FromScratch = lazy(() => import("../pages/FromScratch"));
const ViralTweets = lazy(() => import("../pages/ViralTweets"));
const Personalized = lazy(() => import("../pages/Personalized"));
const Lists = lazy(() => import("../pages/Lists"));
const ViewList = lazy(() => import("../pages/ViewList"));
const Campaign = lazy(() => import("../pages/Campaign"));
const ViewCampaign = lazy(() => import("../pages/ViewCampaign"));
const Onboarding = lazy(() => import("../pages/Onboarding"));
const TweetGenerator = lazy(() => import("../pages/TweetGenerator"));
const ConnectingTwitter = lazy(() => import("../pages/ConnectingTwitter"));

const Page404 = lazy(() => import("../pages/404"));
const routes = [
  {
    path: "/", // the url
    component: ViralTweets, // view rendered
    title: "Tweet Ideas For You",
  },
  {
    path: "/tweet-generator", // the url
    component: TweetGenerator, // view rendered
    title: "Tweet Generator",
  },
  {
    path: "/viral-tweets",
    component: ViralTweets,
    title: "Tweet Ideas For You",
  },
  {
    path: "/keyword-search",
    component: SearchTweet,
    title: "Keyword Search",
  },
  {
    path: "/industry-search",
    component: IndustrySearch,
    title: "Industry Search",
  },
  {
    path: "/my-collections",
    component: MyCollection,
    title: "Rediscover Your Twitter Gems",
  },
  {
    path: "/settings",
    component: Settings,
    title: "Settings",
  },
  {
    path: "/my-account",
    component: Settings,
    title: "Settings",
  },
  {
    path: "/change-password",
    component: Settings,
    title: "Settings",
  },
  {
    path: "/personalization",
    component: Settings,
    title: "Settings",
  },
  {
    path: "/billing",
    component: Settings,
    title: "Settings",
  },
  {
    path: "/connected-accounts",
    component: Settings,
    title: "Settings",
  },
  {
    path: "/home", // the url
    component: Home, // view rendered
  },
  {
    path: "/queue",
    component: Queue,
    title: "Upcoming Tweets",
  },
  {
    path: "/published-tweets",
    component: PostedTweet,
    title: "Published Tweets",
  },
  {
    path: "/analytics",
    component: Analytics,
    title: "Analytics",
  },
  {
    path: "/twitter-engage",
    component: TwitterEngage,
    title: "Engage",
  },
  {
    path: "/ai-tweet-assistant/from-scratch",
    component: FromScratch,
    title: "From Scratch",
  },
  {
    path: "/ai-tweet-assistant/personalized-for-you",
    component: Personalized,
    title: "Personalized for you",
  },
  {
    path: "/onboarding",
    component: Onboarding,
  },
  {
    path: "/404", // the url
    component: Page404, // view rendered
  },
  {
    path: "/twitter-crm/lists",
    component: Lists,
    title: "Lists",
  },
  {
    path: "/twitter-crm/campaign",
    component: Campaign,
  },
  {
    path: "/twitter-crm/view-list/:id",
    component: ViewList,
  },
  {
    path: "/twitter-crm/view-campaign/:id",
    component: ViewCampaign,
  },
  {
    path: "/connecting-twitter",
    component: ConnectingTwitter,
  },
];

export default routes;
